import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'

import SocialLinks from './socialLinks'

/**
* Footer
*
* Global footer component to be displayed at the bottom of every page
*
*/
const Footer = ({ data, isHome }) => (

	<footer className="footer site-footer">

		<div className="level">
			<section className="level-item">
				&copy; {data.name}, {new Date().getFullYear()}
			</section>

			{!isHome &&
				<section className="level-item footer-social-links">
					<SocialLinks data={data} />
				</section>
			}

			{/* <section className="level-item">
				<span>
					<Link className="footer-link" to="/privacy">Privacy</Link> &nbsp; | &nbsp;
				</span>
				<a className="footer-link" href="https://www.tunde.io/" target="_blank" rel="noopener noreferrer">
					<em>tunde.io</em>
				</a>
			</section> */}

		</div>

	</footer>
)

export default Footer

Footer.propTypes = {
	data: PropTypes.object.isRequired,
}