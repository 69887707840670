/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Footer from './footer'
import Hero from '../home/hero'
import Header from './header'
import '../../styles/app.scss'

const Layout = ({ children, isHome, pageTitle }) => {
	const data = useStaticQuery(graphql`
    {
      siteDetails: contentfulSiteDetails {
        name
        themeColour
        url
        heroImage {
          file {
            url
          }
        }
      }
      person:contentfulPerson(name: {eq: "Toyosi Soetan"}) {
        email
        heroBio
		    deezer
		    napster
		    tidal
        instagram
		    spotify
		    youtube
        title
        name
        image {
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
	    }
    }
  `)
	return (
    <>
      {/* Special home hero */}
      {isHome ?
        <>
          <Hero data={data} />
          {children}
        </>

      	:
      // Every other page
      	<div className="site-wrap">
          <>
            <div className="site-top">

            	<Header pageTitle={pageTitle} />

            </div>

            <main className="site-main">
            	<div className="container">
            		<div className="page-content">
            			{children}
            		</div>
            	</div>
            </main>

            <div className="site-bottom">
            	<Footer data={data.person} />
            </div>
          </>
      	</div>
      }

    </>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
