import React from 'react'
import PropTypes from 'prop-types'

/**
* Social links
*
* One component, for one set of social links
* Different component to that used in the navigation bar
*
*/
const SocialLinks = ({ data, cssClass }) => {
	// const facebookUrl = data.facebook
	const instagramUrl = data.instagram
	const youtubeUrl = data.youtube
	const emailLink = `mailto:${data.email}`


	return (
		<>
			<div className="social-links">
				{/* {facebookUrl &&
					<a target="_blank" rel="noopener noreferrer" href={facebookUrl}>
						<span className={`icon align-icon` + cssClass}>
							<i className="ri-facebook-fill" />
						</span>
					</a>
				} */}
				{instagramUrl &&
					<a target="_blank" rel="noopener noreferrer" href={instagramUrl}>
						<span className={`icon align-icon ` + cssClass}>
							<i className="ri-instagram-line" />
						</span>
					</a>
				}
				{youtubeUrl &&
					<a target="_blank" rel="noopener noreferrer" href={youtubeUrl}>
						<span className={`icon align-icon ` + cssClass}>
							<i className="ri-youtube-fill" />
						</span>
					</a>
				}
				{emailLink &&
					<a href={emailLink}>
						<span className={`icon align-icon ` + cssClass}>
							<i className="ri-mail-line" />
						</span>
					</a>
				}
			</div>
		</>
	)
}

export default SocialLinks

SocialLinks.propTypes = {
	data: PropTypes.shape({
		// facebook: PropTypes.string.isRequired,
		instagram: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		youtube: PropTypes.string.isRequired,
	}),
}
