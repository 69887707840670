import React from 'react'
import { Link } from 'gatsby'

import Navbar from '../common/navbar'
import SocialLinks from '../common/socialLinks'
import Footer from '../common/footer'

/**
* Hero
*
* Our big beautiful hero with details from the latest single/album
* To be used on the Home/Index page only
*/

const Hero = ({ data }) => (
	<>
		<section
			id="homeHero"
			className="hero is-fullheight home-hero home-hero-head"
			style={{ backgroundImage: `url(${data.siteDetails.heroImage.file.url})` }}
		>
			<div className="hero-head home-hero-head">
				<Navbar navClass={`navbar is-transparent is-spaced`} />
			</div>

			<div className="hero-body home-hero-body has-text-white">
				<div className="container">
					<div className="columns is-vcentered has-text-centered">
						<div className="column">
							<h1 className="title is-size-1 hero-title has-text-white">{data.person.name}</h1><br />
							<p className="subtitle is-size-2 is-uppercase has-text-white home-hero-subtitle">{data.person.heroBio}</p><br />
							<SocialLinks data={data.person} cssClass="home-hero-social-icons is-size-3" />
							<div className="buttons is-centered">
								<button className="home-hero-cta button is-primary is-link is-medium">
									<Link to="/music">
										<span className="icon align-icon">
											<i className="ri-headphone-fill" />
										</span>
										&nbsp;&nbsp;Listen Now
            							</Link>
								</button>
								<button className="home-hero-cta button is-primary is-link is-medium">
									<Link to="/bio">
										<span className="icon align-icon">
											<i className="ri-information-fill" />
										</span>
										&nbsp;&nbsp;Learn More
            							</Link>
								</button>
								{/* <button className="home-hero-cta button is-primary is-link is-medium">
									<a href="#">
										<span className="icon align-icon">
											<i className="ri-mail-line" />
										</span>
										&nbsp;&nbsp;Subscribe
            							</a>
								</button> */}
							</div>
						</div>
					</div>

				</div>
			</div>

			<div className="hero-foot has-text-centered home-hero-foot">
				<div className="container is-fluid">
					<div className="column">
						
					</div>
				</div>

			</div>
			<Footer data={data.person} isHome={true} />
		</section>
	</>
)

export default Hero