import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

/**
* Main header navigation
*
* Navigation bar to be used across all pages
* Styles may change slightly depending on page
*
*/
const Navbar = ({ navClass }) => (
	<nav className={navClass} role="navigation" aria-label="main navigation">
		<div className="container">
			<div className="navbar-brand">
				<Link className="navbar-item is-size-3" to="/">
					<h1>TS</h1>
				</Link>
				{/* eslint-disable jsx-a11y/anchor-is-valid */}
				<a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarItems" onClick={navExpand}>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div id="navbarItems" className="navbar-menu is-size-5">
				<div className="navbar-end">
					<Link className="navbar-item" to="/bio">Bio</Link>
					<Link className="navbar-item" to="/music">Music</Link>
					<Link className="navbar-item" to="/events">Events</Link>
					<Link className="navbar-item" to="/gallery">Gallery</Link>
					<Link className="navbar-item" to="/contact">Contact</Link>
				</div>
			</div>
		</div>
	</nav>
)

Navbar.propTypes = {
	navClass: PropTypes.string.isRequired,
}

export default Navbar

function navExpand() {
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll(`.navbar-burger`), 0)

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {
		// Add a click event on each of them
		$navbarBurgers.forEach((el) => {
			// Get the target from the "data-target" attribute
			const target = el.dataset.target
			const $target = document.getElementById(target)
			const hero = document.getElementsByClassName(`hero`)[0]

			// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
			el.classList.toggle(`is-active`)
			el.classList.toggle(`nav-open-colour`)
			$target.classList.toggle(`is-active`)
			$target.classList.toggle(`nav-open-colour`)
			hero && hero.classList.toggle(`nav-open-colour`)
		})
	}
}