import React from 'react'

import Navbar from './navbar'

const Header = ({ pageTitle }) => (
	<section className="hero is-primary is-small is-bold">
		<div className="hero-head home-hero-head">
			<Navbar navClass={`navbar`} />
		</div>
		<div className="hero-body">
			<div className="container">
				<div className="columns has-text-centered">
					<div className="column">
						<h1 className="title is-size-1">{pageTitle}</h1>
					</div>
						
				</div>
			</div>
		</div>
	</section>
)

export default Header